* {
    box-sizing: border-box;
}

body {
    /*background-image: url("../Assets/background.jpg");*/
    font-family: 'Roboto', sans-serif;    
}

.root {
    min-height: 100vh;       
}